import React, { useState, useEffect } from 'react';
import { PolarArea } from 'react-chartjs-2';
import icon from '../images/icon-n.png';
import ricon from '../images/icon-n-r.png';
import instructions from '../images/instruct-n.png';
import Rinstructions from '../images/r-instruct-n.png';


const handleQuitInstructions = () => {
    const instructionsDiv = document.getElementById('instructions-div');
    if (instructionsDiv) {
        instructionsDiv.style.display = 'none';
    }
};
const Selector = ({ opciones, seleccion, onSeleccionChange }) => {
    return (
        <select value={seleccion} onChange={(e) => onSeleccionChange(e.target.value)}>
            <option value="" disabled>Seleccione</option>
            {opciones.map((opcion) => (
                <option key={opcion.id} value={opcion.id}>
                    {opcion.nombre}
                </option>
            ))}
        </select>
    );
};


const SlideComponent = ({ actual, first, second, onSaveAnswers }) => {
    const [test, setTest] = useState(null);
    const [currentTest, setCurrentTest] = useState(null)
    const datosDePrueba = first.question
    const [selector1, setSelector1] = useState('');
    const [selector2, setSelector2] = useState('');
    const [selector3, setSelector3] = useState('');
    const [sliderValue, setSliderValue] = useState(0);
    const [title, setTitle] = useState(null);

    const selectedButtonGroup0 = localStorage.getItem('selected0');
    const selectedButtonGroup1 = localStorage.getItem('selected1');
    const selectedButtonGroup2 = localStorage.getItem('selected2');
    const selectedButtonGroup3 = (JSON.parse(localStorage.getItem('selected3') || '[]'));
    const selectedButtonGroup4 = (JSON.parse(localStorage.getItem('selected4') || '[]'));
    const selectedButtonGroup5 = (JSON.parse(localStorage.getItem('selected5') || '[]'));
    const selectedButtonGroup6 = JSON.parse(localStorage.getItem('selected6') || '{}');
    console.log(selectedButtonGroup6);

    useEffect(() => {
        const nextButton = document.querySelector('.btn-next');

        if (selector1 !== '' && selector2 !== '' && selector3 !== '' && sliderValue > 0) {
            nextButton.disabled = false; // Habilitar el botón
        } else {
            nextButton.disabled = true; // Deshabilitar el botón
        }
    }, [selector1, selector2, selector3, sliderValue]);

    useEffect(() => {
        // Obtener el currentTest de localStorage
        const currentTest = JSON.parse(localStorage.getItem('currentTest'));
        const savedTest = JSON.parse(localStorage.getItem('currentTest') || '[]');
        setTitle(savedTest.title);
        if (currentTest) {
            fetchTestDetails(currentTest.id);
        }
    }, []);
    const fetchTestDetails = (testId) => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/getTest/${testId}`;
        xhr.open("GET", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const data = JSON.parse(xhr.responseText);
                setTest(data.object);
                setCurrentTest(data.title);
                console.log("Test Details Loaded: ", data);
            }
        };
        xhr.send();
    };
    const handleSelector1Change = (value) => {
        setSelector1(value);
        setSelector2(''); // Reiniciar selector2
        setSelector3(''); // Reiniciar selector3
    };

    const handleSelector2Change = (value) => {
        setSelector2(value);
        setSelector3(''); // Reiniciar selector3
    };

    const opciones1 = datosDePrueba.map(d => ({ id: d.variedad, nombre: d.variedad }));

    const opciones2 = selector1
        ? datosDePrueba.find(d => d.variedad === selector1)?.paises.map((p, index) => ({ id: p.pais, nombre: p.pais }))
        : [];

    const opciones3 = selector2
        ? datosDePrueba.find(d => d.variedad === selector1)?.paises.find(p => p.pais === selector2)?.regiones.map((r, index) => ({ id: r.region, nombre: r.region }))
        : [];

    const data = {
        labels: ['', '', '', '', ''],
        datasets: [
            {
                label: 'Acidez',
                data: [selectedButtonGroup6?.b + 1 || 0, 0, 0, 0, 0],
                backgroundColor: 'rgba(210, 164, 62, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Tanino',
                data: [0, selectedButtonGroup6?.c + 1 || 0, 0, 0, 0],
                backgroundColor: 'rgba(106, 16, 16, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 1,
            },
            {
                label: 'Cuerpo',
                data: [0, 0, selectedButtonGroup6?.d + 1 || 0, 0, 0],
                backgroundColor: 'rgba(84, 34, 61, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Final',
                data: [0, 0, 0, selectedButtonGroup6?.e + 1 || 0, 0],
                backgroundColor: 'rgba(29, 154, 120, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Dulzor',
                data: [0, 0, 0, 0, selectedButtonGroup6?.a + 1 || 0],
                backgroundColor: 'rgba(167, 149, 129, 0.7)',
                borderColor: 'rgba(167, 149, 129, 0)',
                borderWidth: 3,
            },
        ],
    };
    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)', // Color de las l�neas del grid
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)', // Color del grid
                },
                gridLines: {
                    color: 'rgba(0, 0, 0, 1)', // Color del grid exterior
                },
                suggestedMin: 0,
                suggestedMax: 3,
                ticks: {
                    display: false,
                },
                pointLabels: {
                    font: {
                        size: 0, // Establecer el tama�o de la fuente a 0
                    },
                    fontColor: 'white', // Establecer el color del texto como transparente
                },
            },
        },
        plugins: {
            legend: false, // Oculta la leyenda
            tooltip: { // Aseg�rate de usar "tooltip" en singular para la versi�n 3.x en adelante
                enabled: true,
                callbacks: {
                    label: function (context) {
                        // Retorna solo el nombre del dataset. Ejemplo: 'Dataset 1'
                        // Para este ejemplo, se asume que el nombre del dataset est� disponible.
                        // Si quieres cambiar el valor num�rico por una palabra:
                        // return context.dataset.label + ': ' + 'TuPalabraAqui';
                        // Donde 'TuPalabraAqui' es la palabra que quieres mostrar en lugar del valor num�rico.
                        return context.dataset.label;
                    },
                }
            },
        },
    };

    let tastea;
    if (selectedButtonGroup6.a === 0) {
        tastea = 'Seco';
    } else if (selectedButtonGroup6.a === 1) {
        tastea = 'Casi seco / medio';
    } else if (selectedButtonGroup6.a === 2) {
        tastea = 'Dulce';
    } else {
        tastea = ''; // default value
    }
    let tasteb;
    if (selectedButtonGroup6.b === 0) {
        tasteb = 'Baja';
    } else if (selectedButtonGroup6.b === 1) {
        tasteb = 'Media';
    } else if (selectedButtonGroup6.b === 2) {
        tasteb = 'Alta';
    } else {
        tasteb = ''; // default value
    }
    let tastec;
    if (selectedButtonGroup6.c === 0) {
        tastec = 'Bajo';
    } else if (selectedButtonGroup6.c === 1) {
        tastec = 'Medio';
    } else if (selectedButtonGroup6.c === 2) {
        tastec = 'Alto';
    } else {
        tastec = ''; // default value
    }
    let tasted;
    if (selectedButtonGroup6.d === 0) {
        tasted = 'Poco';
    } else if (selectedButtonGroup6.d === 1) {
        tasted = 'Medio';
    } else if (selectedButtonGroup6.d === 2) {
        tasted = 'Mucho';
    } else {
        tasted = ''; // default value
    }
    let tastee;
    if (selectedButtonGroup6.e === 0) {
        tastee = 'Corto';
    } else if (selectedButtonGroup6.e === 1) {
        tastee = 'Medio';
    } else if (selectedButtonGroup6.e === 2) {
        tastee = 'Largo';
    } else {
        tastee = ''; // default value
    }

    const RangoInput = () => {
        const [valor, setValor] = useState(sliderValue); // Inicializar el estado local con el valor global

        useEffect(() => {
            setValor(sliderValue); // Sincronizar el estado local con el estado global en cada renderizado
        }, [sliderValue]); // Aseg�rate de incluir sliderValue en las dependencias

        const handleCambio = (e) => {
            setValor(e.target.value); // Actualizar el estado local mientras el usuario interact�a con el slider
        };

        const handleFinCambio = (e) => {
            setSliderValue(e.target.value); // Actualizar el estado global con el valor final del slider
        };


        return (
            <div className="input-price-container">
                
                <input
                    type="range"
                    id="rango"
                    name="rango"
                    min="0"
                    max="100"
                    value={valor}
                    onChange={handleCambio}
                    onMouseUp={handleFinCambio}
                    onTouchEnd={handleFinCambio} 
                />
                <p>Precio: {valor}&euro;</p>
            </div>
        );
    };

    const saveAnswers = async () => {
        const userLogged = JSON.parse(localStorage.getItem('userLogged'));
        const currentTest = JSON.parse(localStorage.getItem('currentTest'));

        if (!userLogged || !currentTest) {
            console.error("User or Test information is missing.");
            return;
        }

        const parseJSON = (value) => {
            try {
                return JSON.parse(value);
            } catch (e) {
                return value;
            }
        };

        const selectedButtonGroup0 = parseJSON(localStorage.getItem('selected0'));
        const selectedButtonGroup1 = parseJSON(localStorage.getItem('selected1'));
        const selectedButtonGroup2 = parseJSON(localStorage.getItem('selected2'));
        const selectedButtonGroup3 = parseJSON(localStorage.getItem('selected3'));
        const selectedButtonGroup4 = parseJSON(localStorage.getItem('selected4'));
        const selectedButtonGroup5 = parseJSON(localStorage.getItem('selected5'));
        const selectedButtonGroup6 = parseJSON(localStorage.getItem('selected6'));
        const selectedButtonGroup7 = {
            selector1,
            selector2,
            selector3,
        };
        const selectedButtonGroup8 = sliderValue;

        console.log(test);
        const { visual, visualStats } = calculateVisualStats(test, selectedButtonGroup0, selectedButtonGroup1);
        const { olfactory, olfactoryStats } = calculateOlfactoryStats(test, selectedButtonGroup2, selectedButtonGroup3, selectedButtonGroup4, selectedButtonGroup5);
        const { taste, tasteStats } = calculateTasteStats(test, selectedButtonGroup6);
        const { variety, varietyStats, countryStats, regionStats } = calculateVarietyStats(test, selectedButtonGroup7);
        const { price, priceStats } = calculatePriceStats(test, selectedButtonGroup8);

        const answers = {
            selectedButtonGroup0,
            selectedButtonGroup1,
            selectedButtonGroup2,
            selectedButtonGroup3,
            selectedButtonGroup4,
            selectedButtonGroup5,
            selectedButtonGroup6,
            selectedButtonGroup7,
            selectedButtonGroup8,
        };

        const payload = {
            object: answers,
            user_id: userLogged.id,
            test_id: currentTest.id,
            visual,
            visual_stats: visualStats,
            olfactory,
            olfactory_stats: olfactoryStats,
            taste,
            taste_stats: tasteStats,
            variety,
            variety_stats: varietyStats,
            country_stats: countryStats,
            region_stats: regionStats,
            price,
            price_stats: priceStats,
        };

        console.log('Sending data:', payload);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/saveAnswer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            console.log('Response status:', response.status);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Success:', data);

            // Borrar las variables del navegador
            localStorage.removeItem('currentTest');
            localStorage.removeItem('selected0');
            localStorage.removeItem('selected1');
            localStorage.removeItem('selected2');
            localStorage.removeItem('selected3');
            localStorage.removeItem('selected4');
            localStorage.removeItem('selected5');
            localStorage.removeItem('selected6');

            // Redirigir a /choose-wine
            window.location.href = '/choose-wine';
        } catch (error) {
            console.error('Error:', error);
        }
    };





    const calculateVisualStats = (testObject, selectedOption0, selectedOption1) => {
        console.log(testObject);
        const intensityOfColor = testObject.find(question => question.title === "Intensidad del color");
        const colorOfWine = testObject.find(question => question.title === "Color del vino");

        let visual = 0;
        let totalPointsAvailable = 0;

        intensityOfColor.question.forEach(option => {
            if (option.option === selectedOption0) {
                visual += option.points;
            }
            totalPointsAvailable += option.points;
        });

        colorOfWine.question.forEach(option => {
            if (option.option === selectedOption1) {
                visual += option.points;
            }
            totalPointsAvailable += option.points;
        });

        const visualStats = (visual / 3).toFixed(2);

        return { visual, visualStats };
    };
    const calculateOlfactoryStats = (testObject, selectedOption2, selectedOptions3, selectedOptions4, selectedOption5) => {
        const intensityOfAroma = testObject.find(question => question.title === "Intensidad del aroma");
        const primaryAromas = testObject.find(question => question.title === "Aromas primarios");
        const secondaryAromas = testObject.find(question => question.title === "Aromas secundarios");
        const tertiaryAromas = testObject.find(question => question.title === "Aromas terciarios");

        let olfactory = 0;
        let totalPointsAvailable = 0;

        intensityOfAroma.question.forEach(option => {
            if (option.option === selectedOption2) {
                olfactory += option.points;
            }
            totalPointsAvailable += option.points;
        });

        primaryAromas.question.forEach(option => {
            if (selectedOptions3.includes(option.option)) {
                olfactory += option.points;
            }
            totalPointsAvailable += option.points;
        });

        secondaryAromas.question.forEach(option => {
            if (selectedOptions4.includes(option.option)) {
                olfactory += option.points;
            }
            totalPointsAvailable += option.points;
        });

        tertiaryAromas.question.forEach(option => {
            if (selectedOption5.includes(option.option)) {
                olfactory += option.points;
            }
            totalPointsAvailable += option.points;
            
        });

        const olfactoryStats = (olfactory / 8).toFixed(2);

        return { olfactory, olfactoryStats };
    };
    const calculateTasteStats = (testObject, selectedOptions6) => {
        const sweetness = testObject.find(question => question.title === "Dulzor");
        const acidity = testObject.find(question => question.title === "Acidez");
        const tannin = testObject.find(question => question.title === "Tanino");
        const body = testObject.find(question => question.title === "Cuerpo");
        const finish = testObject.find(question => question.title === "Final");

        let taste = 0;
        let totalPointsAvailable = 0;

        const addPoints = (question, selectedOption) => {
            question.question.forEach(option => {
                if (option.option === selectedOption) {
                    taste += option.points;
                }
                totalPointsAvailable += option.points;
            });
        };

        addPoints(sweetness, selectedOptions6.a + 1);
        addPoints(acidity, selectedOptions6.b + 1);
        addPoints(tannin, selectedOptions6.c + 1);
        addPoints(body, selectedOptions6.d + 1);
        addPoints(finish, selectedOptions6.e + 1);

        const tasteStats = (taste / 5).toFixed(2);

        return { taste, tasteStats };
    };
    const calculateVarietyStats = (testObject, selectedVariety) => {
        const varietyQuestion = testObject.find(question => question.title === "Variedad");

        let varietyPoints = 0;
        let countryPoints = 0;
        let regionPoints = 0;

        const selectedVarietyObject = varietyQuestion.question.find(variedad => variedad.variedad === selectedVariety.selector1);

        if (selectedVarietyObject) {
            varietyPoints = selectedVarietyObject.points;

            const selectedCountry = selectedVarietyObject.paises.find(pais => pais.pais === selectedVariety.selector2);
            if (selectedCountry) {
                countryPoints = selectedCountry.points;

                const selectedRegion = selectedCountry.regiones.find(region => region.region === selectedVariety.selector3);
                if (selectedRegion) {
                    regionPoints = selectedRegion.points;
                }
            }
        }

        const totalPoints = varietyPoints + countryPoints + regionPoints;
        const varietyStats = (varietyPoints / 12).toFixed(2); // Assuming 12 is the total possible points for variety
        const countryStats = (countryPoints / 3).toFixed(2); // Assuming 5 is the total possible points for country
        const regionStats = (regionPoints / 5).toFixed(2); // Assuming 2 is the total possible points for region

        return { variety: totalPoints, varietyStats, countryStats, regionStats };
    };

    const calculatePriceStats = (testObject, selectedPrice) => {
        const priceQuestion = testObject.find(question => question.title === "Precio");

        let price = 0;
        let priceStats = 0;

        const selectedOption = priceQuestion.question.find(option => Number(option.option) === Number(selectedPrice));

        if (selectedOption) {
            price = selectedOption.points;
            priceStats = price / 4; // Calcula price_stats dividiendo los puntos por 4
        }
        console.log(price);
        console.log(priceStats);
        return { price, priceStats };
    };



    // Pasa la referencia de saveAnswers al padre
    useEffect(() => {
        if (onSaveAnswers) {
            onSaveAnswers(saveAnswers);
        }
    }, [selector1, selector2, selector3, sliderValue]);

    return (
        <div className="slide-container">
            <div className="overlay" id="instructions-div">
                <img src={instructions} alt="Imagen" className="centered-image dekstop" onClick={handleQuitInstructions} />
                <img src={Rinstructions} alt="Imagen" className="centered-image mobile" onClick={handleQuitInstructions} />
            </div>
            <img className="icon-test note-icon-test icon-dekstop" src={icon} alt="icon" />
            <img className="icon-test note-icon-test icon-mobile" src={ricon} alt="icon" />
            <div className="actual-wine">{title}</div>
            <h2>Nota de cata</h2>
            <div className="cont-sub-section">
            <div className="text-note col-sub-section-2">
                    <div className="section-title">Nota de cata</div>
                    <p>Este vino tiene un color <span>{selectedButtonGroup1} {selectedButtonGroup0}</span>. En nariz tiene una <span>intensidad {selectedButtonGroup2}</span> con aromas primarios <span>({selectedButtonGroup3.join(", ")})</span>, aromas secundarios <span>({selectedButtonGroup4.join(", ")})</span> y aromas terciarios <span>({selectedButtonGroup5.join(", ")}). </span>
                        Es un vino <span>{tastea}</span>,
                        con acidez <span>{tasteb}</span>,
                        tanino <span>{tastec}</span>,
                        cuerpo <span> {tasted} </span>
                        y final <span>{tastee}</span>.</p>
                    <div className="note-chart-container"><PolarArea data={data} options={options} /></div>
            </div>
            <div className="col-sub-section-2">
                    <div className="note-select-container">
                        <h4>Variedad (12 pt)</h4>
                        <Selector opciones={opciones1} seleccion={selector1} onSeleccionChange={handleSelector1Change} />
                    </div>
                    <div className="note-select-container">
                        <h4>País (3 pt)</h4>
                        <Selector opciones={opciones2} seleccion={selector2} onSeleccionChange={handleSelector2Change} />
                    </div>
                    <div className="note-select-container">
                        <h4>Región (5 pt)</h4>
                        <Selector opciones={opciones3} seleccion={selector3} onSeleccionChange={setSelector3} />
                    </div>
                <div className="note-select-container">
                        <h4>Precio (4 pt)</h4>
                    <RangoInput />
                </div>
            </div>
            </div>
            
        </div>

    );
};

export default SlideComponent;