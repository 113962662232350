import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TwoButtons from '../components/twoButtons';
import WineA from '../images/wine-a.png';
import WineB from '../images/wine-b.png';
import WineC from '../images/wine-c.png';
import WineD from '../images/wine-d.png';
import close from '../images/close-stats.png';
export default function ChooseComponent() {
    const [testsData, setTestsData] = useState([]);
    const [answersData, setAnswersData] = useState([]);
    const [profileInfo, setProfileInfo] = useState({ id: 0 });
    const [isSubmitPopupVisible, setSubmitPopupVisible] = useState(true);
    const navigate = useNavigate();
   

    const closeSubmitPopup = () => {
        navigate('/');
    }; 
  


  






    return (
        <div className="test-container">
            <div className="slider-container">
                <div className="slide-container">
                    <h2 className="">¿Qué vino vas a catar?</h2>
                    <div className="choose-wine-container">
                        <Link 
                            to="/answer" 
                            className="image-link disabled"
                            
                        >
                            <img src={WineA} alt="Imagen 1" className="grid-image" />
                            <span>A</span>
                        </Link>
                        <Link 
                            to="/answer" 
                            className="image-link disabled"
                           
                        >
                            <img src={WineB} alt="Imagen 1" className="grid-image" />
                            <span>B</span>
                        </Link>
                        <Link 
                            to="/answer" 
                            className="image-link disabled"
                            
                        >
                            <img src={WineC} alt="Imagen 1" className="grid-image" />
                            <span>C</span>
                        </Link>
                        <Link 
                            to="/answer" 
                            className="image-link disabled"
                        >
                            <img src={WineD} alt="Imagen 1" className="grid-image" />
                            <span>D</span>
                        </Link>
                    </div>
                </div>
            </div>
            <TwoButtons />
            {isSubmitPopupVisible && (
                <Popup
                    text="¡Ups! Parece que no tienes una suscripción activa. ¡Únete a la wineparty en winegang.es y comienza a jugar!"
                    onClose={closeSubmitPopup}
                    isHelpPopup={false}
                />
            )}
        </div>
    );
};
const Popup = ({ text, onClose, onConfirm, imageUrl, isHelpPopup }) => {
    return (
        <div className="custom-overlay">
            <div className={`custom-popup ${isHelpPopup ? 'popup-noback' : ''}`}>
                <button className="stats-close" onClick={onClose}><img src={close} alt="close" /></button>
                {text && <p>{text}</p>}
                {imageUrl && (
                    <div className="image-container">
                        <img src={imageUrl} alt="Ayuda" />
                    </div>
                )}
                {onConfirm && <button onClick={onConfirm}>Enviar</button>}
            </div>
        </div>
    );
};