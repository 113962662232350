import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TwoButtons from '../components/twoButtons';
import WineA from '../images/wine-a.png';
import WineB from '../images/wine-b.png';
import WineC from '../images/wine-c.png';
import WineD from '../images/wine-d.png';

export default function ChooseComponent() {
    const [testsData, setTestsData] = useState([]);
    const [profileInfo, setProfileInfo] = useState({ id: 0 });
    const navigate = useNavigate();

    useEffect(() => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo(userInfo);
        }
    }, []);
    const clearSessionData = () => {
        localStorage.removeItem('currentTest');
        localStorage.removeItem('selected0');
        localStorage.removeItem('selected1');
        localStorage.removeItem('selected2');
        localStorage.removeItem('selected3');
        localStorage.removeItem('selected4');
        localStorage.removeItem('selected5');
        localStorage.removeItem('selected6');
    };
    const checkForCurrentTest = () => {
        const currentTest = localStorage.getItem('currentTest');
        if (currentTest) {
            const parsedCurrentTest = JSON.parse(currentTest);
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/getTests/${profileInfo.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    const testAvailable = data.some(test => test.id === parsedCurrentTest.id);
                    if (testAvailable) {
                        navigate('/test');
                    } else {
                        clearSessionData();
                    }
                }
            };
            xhr.send();
        }
    };
    useEffect(() => {
        if (profileInfo && profileInfo.id) {
            checkForCurrentTest();
        }
    }, [profileInfo]);

    const getTests = () => {
        if (profileInfo && profileInfo.id) {
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/getTests/${profileInfo.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        setTestsData(data);
                        console.log(data);
                    } else if (xhr.status === 204) {
                        navigate('/wine-order');
                    } else if (xhr.status === 205) {
                        navigate('/choose-answer-alert'); // Cambia '/another-page' por la ruta que necesites
                    } else if (xhr.status === 403) {
                        navigate('/subscription-alert'); // Cambia '/another-page' por la ruta que necesites
                    } else if (xhr.status === 203) {
                        navigate('/game-alert'); // Cambia '/another-page' por la ruta que necesites
                    }
                }
            };
            xhr.send();
        }
    };

    useEffect(() => {
        if (profileInfo && profileInfo.id) { // A�adido check para asegurar que profileInfo tiene un id
            getTests();
        }
    }, [profileInfo]);

    const handleWineClick = (e, wineTitle) => {
        const selectedTest = testsData.find(test => test.title === wineTitle);
        if (selectedTest) {
            localStorage.setItem('currentTest', JSON.stringify(selectedTest));
            console.log("Current Test Saved: ", selectedTest);
        } else {
            e.preventDefault(); // Previene la navegaci�n si el test no est� disponible
            console.log("Test not available for wine: ", wineTitle);
        }
    };

    const isWineDisabled = (wineTitle) => {
        return !testsData.some(test => test.title === wineTitle);
    };

    return (
        <div className="test-container">
            <div className="slider-container">
                <div className="slide-container">
                    <h2 className="">&iquest;Qu&eacute; vino vas a catar?</h2>
                    <div className="choose-wine-container">
                        <Link 
                            to="/test" 
                            className={`image-link ${isWineDisabled('A') ? 'disabled' : ''}`}
                            onClick={(e) => handleWineClick(e, 'A')}
                        >
                            <img src={WineA} alt="Imagen 1" className="grid-image" />
                            <span>A</span>
                        </Link>
                        <Link 
                            to="/test" 
                            className={`image-link ${isWineDisabled('B') ? 'disabled' : ''}`}
                            onClick={(e) => handleWineClick(e, 'B')}
                        >
                            <img src={WineB} alt="Imagen 1" className="grid-image" />
                            <span>B</span>
                        </Link>
                        <Link 
                            to="/test" 
                            className={`image-link ${isWineDisabled('C') ? 'disabled' : ''}`}
                            onClick={(e) => handleWineClick(e, 'C')}
                        >
                            <img src={WineC} alt="Imagen 1" className="grid-image" />
                            <span>C</span>
                        </Link>
                        <Link 
                            to="/test" 
                            className={`image-link ${isWineDisabled('D') ? 'disabled' : ''}`}
                            onClick={(e) => handleWineClick(e, 'D')}
                        >
                            <img src={WineD} alt="Imagen 1" className="grid-image" />
                            <span>D</span>
                        </Link>
                    </div>
                </div>
            </div>
            <TwoButtons />
        </div>
    );
}
